export default {
  computed: {
    bedroomOptions() {
      if (this.$i18n.locale !== 'sr') {
        const options = [];
        options.push({
          value: 0,
          text: 'Studio',
        });
        options.push({
          value: 1,
          text: '1 bedroom',
        });
        for (let i = 2; i < 51; i += 1) {
          options.push({
            value: i,
            text: `${i} bedrooms`,
          });
        }
        return options;
      }
      return [
        {
          value: 0,
          text: 'Studio',
        },
        {
          value: 1,
          text: '1 spavaća soba',
        },
        {
          value: 2,
          text: '2 spavaće sobe',
        },
        {
          value: 3,
          text: '3 spavaće sobe',
        },
        {
          value: 4,
          text: '4 spavaće sobe',
        },
        {
          value: 5,
          text: '5 spavaćih soba',
        },
        {
          value: 6,
          text: '6 spavaćih soba',
        },
        {
          value: 7,
          text: '7 spavaćih soba',
        },
        {
          value: 8,
          text: '8 spavaćih soba',
        },
        {
          value: 9,
          text: '9 spavaćih soba',
        },
        {
          value: 10,
          text: '10 spavaćih soba',
        },
        {
          value: 11,
          text: '11 spavaćih soba',
        },
        {
          value: 12,
          text: '12 spavaćih soba',
        },
        {
          value: 13,
          text: '13 spavaćih soba',
        },
        {
          value: 14,
          text: '14 spavaćih soba',
        },
        {
          value: 15,
          text: '15 spavaćih soba',
        },
        {
          value: 16,
          text: '16 spavaćih soba',
        },
        {
          value: 17,
          text: '17 spavaćih soba',
        },
        {
          value: 18,
          text: '18 spavaćih soba',
        },
        {
          value: 19,
          text: '19 spavaćih soba',
        },
        {
          value: 20,
          text: '20 spavaćih soba',
        },
        {
          value: 21,
          text: '21 spavaća soba',
        },
        {
          value: 22,
          text: '22 spavaće sobe',
        },
        {
          value: 23,
          text: '23 spavaće sobe',
        },
        {
          value: 24,
          text: '24 spavaće sobe',
        },
        {
          value: 25,
          text: '25 spavaćih soba',
        },
        {
          value: 26,
          text: '26 spavaćih soba',
        },
        {
          value: 27,
          text: '27 spavaćih soba',
        },
        {
          value: 28,
          text: '28 spavaćih soba',
        },
        {
          value: 29,
          text: '29 spavaćih soba',
        },
        {
          value: 30,
          text: '30 spavaćih soba',
        },
        {
          value: 31,
          text: '31 spavaća soba',
        },
        {
          value: 32,
          text: '32 spavaće sobe',
        },
        {
          value: 33,
          text: '33 spavaće sobe',
        },
        {
          value: 34,
          text: '34 spavaće sobe',
        },
        {
          value: 35,
          text: '35 spavaćih soba',
        },
        {
          value: 36,
          text: '36 spavaćih soba',
        },
        {
          value: 37,
          text: '37 spavaćih soba',
        },
        {
          value: 38,
          text: '38 spavaćih soba',
        },
        {
          value: 39,
          text: '39 spavaćih soba',
        },
        {
          value: 40,
          text: '40 spavaćih soba',
        },
        {
          value: 41,
          text: '41 spavaća soba',
        },
        {
          value: 42,
          text: '42 spavaće sobe',
        },
        {
          value: 43,
          text: '43 spavaće sobe',
        },
        {
          value: 44,
          text: '44 spavaće sobe',
        },
        {
          value: 45,
          text: '45 spavaćih soba',
        },
        {
          value: 46,
          text: '46 spavaćih soba',
        },
        {
          value: 47,
          text: '47 spavaćih soba',
        },
        {
          value: 48,
          text: '48 spavaćih soba',
        },
        {
          value: 49,
          text: '49 spavaćih soba',
        },
        {
          value: 50,
          text: '50 spavaćih soba',
        },
      ];
    },
  },
  methods: {
    isAdPending(ad) {
      return ad.status === 'pending' || ad.status === 'pending_fix';
    },
    isAdActive(ad) {
      return Boolean(Number(ad.active[this.$i18n.locale])) && ad.status === 'listed';
    },
    getAdSinglePagePreviewUrl(ad) {
      if (ad.id && ad.active && Boolean(ad.active[this.$i18n.locale])) {
        return `${process.env.VUE_APP_APP_URL}/${this.$i18n.locale === 'en' ? '' : `${this.$i18n.locale}/`}ad/${ad.id}`;
      }
      if (ad.id && this.isAdPending(ad)) {
        return `${process.env.VUE_APP_APP_URL}/${this.$i18n.locale === 'en' ? '' : `${this.$i18n.locale}/`}preview/${ad.id}`;
      }
      return undefined;
    },
    getNovaUserUrl(id) {
      return `${process.env.VUE_APP_APP_URL}/nova/resources/users/${id}`;
    },
    getDestinationSearchUrl(citySlug, categorySlug) {
      return `${process.env.VUE_APP_APP_URL}/${this.$i18n.locale === 'en' ? '' : `${this.$i18n.locale}/`}${citySlug}/${categorySlug}`;
    },
  },
};
